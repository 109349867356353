import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { defaultIfEmpty, map } from 'rxjs/operators';

import { ControllerConfigurationInfo, ControllerConfigurationResource } from '@priva/next-model';

@Injectable({
    providedIn: 'root',
})
export class ConfigurationFilesService {
    private readonly httpClient = inject(HttpClient);

    public downloadConfigurations(
        configInfos: ControllerConfigurationInfo[],
    ): Observable<ControllerConfigurationResource[]> {
        const configItems = configInfos.reduce((configItems, configInfo) => {
            configItems.push(
                ...configInfo.configurationItems.map((ci) => ({
                    ...ci,
                    controllerId: configInfo.controllerId,
                    cxssVersion: configInfo.cxssVersion,
                })),
            );
            return configItems;
        }, []);

        return forkJoin(
            configItems
                .filter((ci) => !!ci.fileUrl) // Only download files that have a fileUrl
                .map((configInfo) =>
                    this.httpClient
                        .get(configInfo.fileUrl, { observe: 'response', responseType: 'blob' })
                        .pipe(
                            map((resp) => {
                                return {
                                    contents: resp.body,
                                    path: configInfo.fileUrl,
                                    fileName: configInfo.fileName,
                                    controllerId: configInfo.controllerId,
                                    configurationId: configInfo.id,
                                    configurationVersion: configInfo.cxssVersion, // Remember, when downloaded, cxssVersion === configurationVersion
                                    cxssVersion: configInfo.cxssVersion,
                                };
                            }),
                        ),
                ),
        ).pipe(defaultIfEmpty([]));
    }
}
